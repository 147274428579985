var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c(
            "div",
            [
              _vm.tableDataRows.length > 0 && !_vm.isVerticalTable
                ? _c("table", { staticClass: "pioti-table table-auto" }, [
                    _c(
                      "thead",
                      _vm._l(_vm.tableHeaders, function(header, i) {
                        return _c(
                          "tr",
                          { key: i, class: { green: _vm.headerColor } },
                          [
                            _vm._l(_vm.columns, function(hCol, ix) {
                              return _c("th", { key: ix }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("cucUpperCase")(
                                        _vm._f("tableHeaderSpace")(hCol)
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            }),
                            _vm.isActions
                              ? _c("th", { staticClass: "actions" })
                              : _vm._e()
                          ],
                          2
                        )
                      }),
                      0
                    ),
                    _c(
                      "tbody",
                      _vm._l(_vm.tableDataRows, function(row, index) {
                        return _c(
                          "tr",
                          { key: index },
                          [
                            _vm._l(_vm.columns, function(col, idx) {
                              return _c(
                                "td",
                                {
                                  key: idx,
                                  class: {
                                    tooltip: col == "ColorIndicator",
                                    ingredient: col == "Ingredient"
                                  }
                                },
                                [
                                  col == "ColorIndicator"
                                    ? _c("Status", {
                                        attrs: { statusCode: row[col] }
                                      })
                                    : _c("span", [_vm._v(_vm._s(row[col]))]),
                                  col == "Marked"
                                    ? _c("Checkbox", {
                                        attrs: {
                                          checked: row[col] == 1 ? true : false
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            _vm.isActions
                              ? _c(
                                  "td",
                                  { staticClass: "actions" },
                                  [
                                    _c("MoreMenu", {
                                      attrs: {
                                        menuItems: _vm.moreActions,
                                        rowData: row
                                      },
                                      on: { click: _vm.onActionItemSelect }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ])
                : _vm.tableDataRows.length > 0 && _vm.isVerticalTable
                ? _c(
                    "table",
                    { staticClass: "pioti-table table-auto vertical" },
                    [
                      _c(
                        "tbody",
                        _vm._l(_vm.tableDataRows[0], function(val, name, i) {
                          return _c("tr", { key: i }, [
                            !_vm.headerColor
                              ? _c("td", { staticClass: "font-bold" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("cucUpperCase")(
                                          _vm._f("tableHeaderSpace")(name)
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _c(
                                  "td",
                                  { class: { green: _vm.headerColor } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("cucUpperCase")(
                                            _vm._f("tableHeaderSpace")(name)
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                            _c(
                              "td",
                              { staticClass: "tooltip table-tooltip" },
                              [
                                _c("Ellipsis", {
                                  attrs: { data: val, subString: _vm.subString }
                                })
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  )
                : _c("NoDetailsBox")
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }