<template>
  <div class="table-container">
    <Loader :loading="isLoader" v-if="isLoader" />
    <div v-else>
      <table
        class="pioti-table table-auto"
        v-if="tableDataRows.length > 0 && !isVerticalTable"
      >
        <thead>
          <tr
            v-for="(header, i) in tableHeaders"
            :key="i"
            :class="{ green: headerColor }"
          >
            <th v-for="(hCol, ix) in columns" :key="ix">
              {{ hCol | tableHeaderSpace | cucUpperCase }}
            </th>
            <th v-if="isActions" class="actions"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in tableDataRows" :key="index">
            <td
              v-for="(col, idx) in columns"
              :key="idx"
              :class="{
                tooltip: col == 'ColorIndicator',
                ingredient: col == 'Ingredient',
              }"
            >
              <Status v-if="col == 'ColorIndicator'" :statusCode="row[col]" />

              <span v-else>{{ row[col] }}</span>
              <Checkbox
                v-if="col == 'Marked'"
                :checked="row[col] == 1 ? true : false"
              />
            </td>
            <td v-if="isActions" class="actions">
              <MoreMenu
                :menuItems="moreActions"
                :rowData="row"
                @click="onActionItemSelect"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <!--VERTICAL TABLE-->
      <table
        class="pioti-table table-auto vertical"
        v-else-if="tableDataRows.length > 0 && isVerticalTable"
      >
        <tbody>
          <tr v-for="(val, name, i) in tableDataRows[0]" :key="i">
            <td class="font-bold" v-if="!headerColor">
              {{ name | tableHeaderSpace | cucUpperCase }}
            </td>
            <td :class="{ green: headerColor }" v-else>
              {{ name | tableHeaderSpace | cucUpperCase }}
            </td>
            <!-- <td v-html="val"></td> -->
              <td class="tooltip table-tooltip">
                  <Ellipsis :data="val" :subString="subString" />
                </td>
          </tr>
        </tbody>
      </table>
      <!--NO DATA-->
      <NoDetailsBox v-else />
    </div>
  </div>
</template>

<script>
import "../../filters";
export default {
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "./Loader"),
    MoreMenu: () => import(/* webpackPrefetch: true */ "./MoreMenu"),
    Status: () => import(/* webpackPrefetch: true */ "../common/Status"),
    Checkbox: () => import(/* webpackPrefetch: true */ "../common/Checkbox"),
    Ellipsis: () => import(/* webpackPrefetch: true */ "../common/Ellipsis"),
    NoDetailsBox: () =>
      import(/* webpackPrefetch: true */ "../common/NoDetailsBox"),
  },
  props: {
    headerColor: {
      type: Boolean,
      default: false,
    },
    tableHeaders: {
      type: Array,
    },
    tableDataRows: {
      type: Array ? Array : Object,
    },
    moreActions: {
      type: Array,
      default: null,
    },
    isVerticalTable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoader: true,
    subString:90
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
    }, 500);
  },
  computed: {
    isActions() {
      return this.moreActions == null ? false : true;
    },
    columns() {
      if (this.tableDataRows.length == 0) {
        return [];
      }
      return Object.keys(this.tableDataRows[0]);
    },
  },
  methods: {
    onActionItemSelect(rowdata) {
      this.$emit("on-item-select", rowdata);
    },
  },
};
</script>
